import { useState, createRef } from 'react'
import  {Multiselect} from 'multiselect-react-dropdown'

function AddMultiOptionField({ inputType, add, close }){
    const [err, setErr ] = useState("")
    const [opterr, setOpterr ] = useState("")

    const [title, setTitle] = useState("")
    const [required, setRequired] = useState(false)
    const inputRef = createRef()
    const [options, setOptions] = useState([])
    const [option, setOption] = useState("")

    const addField = () => {
        if(!title.trim()) return setErr("Title is required")
        if(title.trim().length < 4) return setErr("Title should be atleast 4 characters long")
        if(options.length < 1) return setErr("Atleast one option is required")
        add({
            title,
            required,
            options,
            type: inputType
        })
        close()
    }

    const addOption = () => {
        if(!option.optionValue.trim()) return setOpterr("Option is required")
        console.log("options28",options);
        console.log("option",option);

        let _opts = [...options]
        _opts.push(option)
        console.log(_opts,"opts");
        setOptions(_opts)
        setOption("")
        console.log("options",options);
        inputRef.current.value = ""
        setOpterr("")
    }
    return (
        <div>
            <div className="input">
                <label>Enter field title</label>
                <input type="text" placeholder={`Eg. Select your ${inputType === "multioption-multianswer" ? "skills" : "gender"}`} onChange={e => setTitle(e.target.value)} />
            </div>
            { options.length > 0 && (
                <div className="mb-1">
                    <p className="b">Options</p>
                    {inputType === "multioption-multianswer" ? <Multiselect options={options} displayValue='optionValue'/>
                    : <select className='selectInput'>
                        { options.map((opt, index) => (      
                            <option value={opt.optionValue} key={index} className='w-100'> {opt.optionValue}</option>
                        )) }
                    </select>}
                </div>
            )}
            {/* <div className="input inline mb-0" key={index}>
                <input type={inputType === "multioption-singleanswer" ? "radio" : "checkbox"} className="mr-1" name="inputs" />
                <label>{opt}</label>
            </div> */}
            <div className="input grey-container p-1">
                <input type="text" className="mb-1" placeholder="Enter a option" onChange={e => setOption({optionValue:e.target.value})} ref={inputRef} />
                {opterr && <p className="err mb-1 text-small">{opterr}</p>}
                <button className="btn" onClick={addOption}>Add Option</button>
            </div>
            <div className="input inline">
                <label>Required: </label>
                <input type="checkbox" onChange={() => setRequired(!required)} />
            </div>
            {err && <p className="err mb-1">{err}</p>}
            <button className="btn" onClick={addField}>add field</button>
        </div>
    )
}

export default AddMultiOptionField