import { useState } from 'react'

import { arrayToggle } from "../utils"
import  {Multiselect} from 'multiselect-react-dropdown'

function MultiOptionField({fieldModel, onSelected }){
    const [selected, setSelected] = useState([])

    const addOption = opt => {
        let _selected = [...selected]
        if(fieldModel.type === "multioption-multianswer"){
            arrayToggle(_selected, opt)
        }else{
            _selected = [opt]
        }
        setSelected(_selected)
        onSelected(_selected)
    }    

    return (
        <div className="input">
            {/* <label>{fieldModel.title}{fieldModel.required && <span className="err">*</span>}</label> */}
            <label>{fieldModel.title}{fieldModel.required ? <span className="err">*</span> :''}</label>
            {fieldModel.type === "multioption-multianswer" ? <Multiselect options={fieldModel.options} displayValue='optionValue' onSelect={(e,i) => addOption(i.optionValue)} style={{option:{color: '#000000'}}}/>
            : <select className='selectInput' onChange={(e)=> addOption(e.target.value)}>
                { fieldModel.options.map((option, index) => (
                    <option value={option.optionValue} key={index} className='selectinput'> {option.optionValue}</option>                        
                )) }
            </select>}
            {/* <div className="input inline mb-0" key={index}>
                <input type={inputType === "multioption-singleanswer" ? "radio" : "checkbox"} className="mr-1" name="inputs" />
                <label>{opt}</label>
            </div> */}
            {/* {fieldModel.options.map((option, index) => (
                <div className="input inline" key={index}>
                    <input type={fieldModel.type === "multioption-multianswer" ? "checkbox" : "radio"}  className="mr-1" name={fieldModel.title.replace(" ", "")} onChange={() => addOption(option)} />
                    <label>{option}</label>
                </div>
            ))} */}
        </div>
    )
}

export default MultiOptionField