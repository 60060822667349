import { useState, useEffect } from "react"
import { auth, firestore, storage } from "./firebase"

export const signup = async (email, pwd) => auth.createUserWithEmailAndPassword(email.trim(), pwd.trim())
export const login = async (email, pwd) => auth.signInWithEmailAndPassword(email.trim(), pwd.trim())
export const logout = () => {
    localStorage.setItem("gfc-user", "")
    return auth.signOut()
}

export const useAuthenticated = () => {
    const [user, setUser] = useState({})
    useEffect(() => {
        auth.onAuthStateChanged(usr => setUser(usr))
    }, [])
    return user
}

export const createForm = formModel => {
    const user = JSON.parse(localStorage.getItem("gfc-user"))
    console.log(formModel," create form");
    return firestore.collection("forms").add({...formModel, uid: user.uid})
}

export const getForms = async () => {
    const user = JSON.parse(localStorage.getItem("gfc-user"))
    let docs = await firestore.collection("forms").get({
        uid: user.uid
    })
    docs = docs.docs
    console.log(docs,"form doc data")
    let forms = docs.map(doc => ({...doc.data(), id: doc.id}))
    console.log(forms,"getform fromsss");
    return forms
}

export const getForm = async ops => {
    console.log(ops,"opsss");
    const user = JSON.parse(localStorage.getItem("gfc-user"))
    let docs = await firestore.collection("forms").get({uid: user.uid})
    let doc = docs.docs
    console.log(doc,"all d data");
    let forms = doc.map(doc => ({...doc.data(), id: doc.id}))
    console.log(forms,"getform fromsss");
    let docu
    forms.forEach((e)=>{
        console.log(e,"each form");
        if(e.id === ops.id){
            docu = e
        }
    })
    return docu
}

export const deleteForm = async formId => {
    let submissions = await firestore.collection("submissions").where("formId", "==", formId).get()
    submissions = submissions.docs
    for(let submission of submissions) {
        await firestore.collection("submissions").doc(submission.id).delete()
    }
    return firestore.collection("forms").doc(formId).delete()
}

export const uploadFile = (file, fileName) => {
    let ref = storage.ref("files/" + fileName)
    return ref.put(file)
}

export const submitForm = (submission, formId) => {console.log("submission",submission);firestore.collection("submissions").add({
    submission,
    formId
})}

export const getSubmissions = async opts => {
    console.log("opt",opts);
    let docs = await firestore.collection("submissions").get(opts)
    docs = docs.docs
    let submissions = docs.map(doc => ({...doc.data(), id: doc.id}))
    let filterSubmision = submissions.filter((submissionItem)=> submissionItem.formId == opts.formID)
    return filterSubmision
}

